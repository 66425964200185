<template>
  <div class="wrap">
    <Loading v-show="$store.state.isLoading"/>
    <van-form v-show="!$store.state.isLoading" @submit="onSubmit" input-align="right" error-message-align="right">
      <van-field
        readonly
        v-model="userName"
        name="userName"
        label="姓名"
      />
      <van-field
        v-if="columns.length =='1'"
        autosize
        readonly
        v-model="contractNum"
        name="contractNum"
        label="合同号"
      />
      <van-field
        v-if="columns.length !='1'"
        autosize
        readonly
        clickable
        name="contractNum"
        :value="contractNum"
        label="合同号"
        @click="showContractPicker = true"
        right-icon="arrow-down"
      />
      <van-popup v-model="showContractPicker" position="bottom">
        <van-picker
          show-toolbar
          :columns="columns"
          @confirm="onContractConfirm"
          @cancel="showContractPicker = false"
        />
      </van-popup>
      <van-field
        readonly
        v-model="vin"
        name="vin"
        label="车架号"
      />
      <van-field
        readonly
        v-model="oldTelFormat"
        name="oldTelFormat"
        label="修改前手机号"
      />
      <van-field
        :readonly="isReadonly"
        v-model="newTel"
        name="newTel"
        label="新手机号"
        :formatter="formatter"
        maxlength="11"
        placeholder="请输入手机号"
        type="number"
        :rules="[
          { required: true},
          { validator:validator1, message: '请输入正确的手机号' },
          { validator:validator2, message: '新的手机号不能与旧的手机号一致' }
         ]"
      />
      <van-field
        v-if="isReadonly"
        readonly
        v-model="currentTime"
        name="currentTime"
        label="申请时间"
      />
      <div class="tips">
        <p>温馨提示：</p>
        <p>1、客户修改的新手机号需客户本人自用的手机，以便后期通知及信息可以及时接收。</p>
        <p>2、此业务在您申请成功后3个工作日内修改完成。</p>
        <p>3、可在合同查询中查看是否已变更。</p>
        <p>4、申请进度可在【我的申请】中查看。</p>
      </div>
      <!-- <a target="_self" href="http://10.6.128.102:8642/get_token?percode=041sGTFa14M70A0GRwGa1TTTMD0sGTFM">aaaaaaaaaaaaaaaaa</a> -->
      <van-col span="20" offset="2" style="position:fixed;bottom:10px;">
        <van-button :disabled="isReadonly" round block type="danger" native-type="submit">
          提交
        </van-button>
      </van-col>
    </van-form>
  </div>
</template>
<script>
  import moment from 'moment';
  import { Toast,Dialog } from 'vant';
  import { mobileNoHide } from '@/lib/format';
  import { isPhoneNum } from '@/lib/validate';
  import {modifyInfo,modifyInfoSub,getOpenId} from '@/server'
  import Loading from "@/components/Loading";
  import {setCookie,getCookie,domainUrl} from '@/lib/utils';
  export default {
    components: {
      Loading
    },
  data() {
    return {
      cardId:this.$store.state.cardId,
      accessToken:this.$store.state.accessToken,
      // openId:localStorage.getItem("openId") ||this.$store.state.openId,
      openId:getCookie('openId'),
      code:this.$route.query.code || '',
      isReadonly:false,
      userName: '',
      contractNum: '',
      vin: '',
      oldTel: '',
      oldTelFormat:'',
      newTel:'',
      currentTime:'',
      showContractPicker: false,
      columns: [],
      dataList:[],
      msg:''
    };
  },
  created(){
    sessionStorage.setItem('key', 2);
    //如果有openid
    if(this.openId){
      getOpenId({openid:this.openId}).then(res=>{
        if(res.wx_code=='0'){
          this.$store.commit('bindStatus', res.bind_status);
          localStorage.setItem("bindStatus", res.bind_status);
          localStorage.setItem("accessToken", res.access_token);
          this.$store.commit('accessToken', res.access_token);
          setCookie('openId',res.openid);
          this.$store.commit('openId', res.openid);
          this.$store.commit('cardId', res.card_id);
          localStorage.setItem("cardId", res.card_id);
          this.cardId = res.card_id;
          this.accessToken = res.access_token;
          this.openId = res.openid;
          if(res.bind_status !=='1'){
            this.$router.push({ path: '/login?redirect=/modifyInfo' });
          }else{
            this.getDetail();
          }
        }else{
          Toast.fail({
            message: res.msg,
            duration:'3000'
          });
        }
      })
    } else{
      if(!this.code) {
        this.$store.commit('bindStatus', '1');
        localStorage.setItem("bindStatus", '1');
        let uiCode = encodeURIComponent(domainUrl+'/modifyInfo');
        window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxe40be82e1238dfe7&redirect_uri='+uiCode+'&response_type=code&scope=snsapi_userinfo&state=STATE&connect_redirect=1#wechat_redirect';         
      }else{//获取code
        getOpenId({percode:this.code}).then(res=>{
          if(res.wx_code=='0'){
            this.$store.commit('bindStatus', res.bind_status);
            localStorage.setItem("bindStatus", res.bind_status);
            this.$store.commit('accessToken', res.access_token);
            localStorage.setItem("accessToken", res.access_token);
            this.$store.commit('openId', res.openid);
            setCookie('openId',res.openid);
            this.$store.commit('cardId', res.card_id);
            localStorage.setItem("cardId", res.card_id);
            this.cardId = res.card_id;
            this.accessToken = res.access_token;
            this.openId = res.openid;
            if(res.bind_status !=='1'){
              this.$router.push({ path: '/login?redirect=/modifyInfo' });
            }else{
              this.getDetail();
            }
          }else{
            Toast.fail({
              message: res.msg,
              duration:'3000'
            });
          } 
        });
      }
    }
  },
  methods: {
    validator1(val,rule) {
      // 11位手机号正则
      const mobileReg = /^1[3456789]\d{9}$/;
      if( mobileReg.test(val)){
        return true;
      }else{
        return false;
      }
    },
    validator2(val,rule) {
      if(val == this.oldTel){
        return false;
      }else{
        return true;
      }
    },
    formatter(value) {
      // 只能输入数字/\D/g,''
      return value.replace(/\D/g, '');
    },
    //格式化手机号码
    formatMobileNoHide(value, dot, digits){
      return mobileNoHide(value, dot, digits)
    },
    // 格式化日期
    formatDate(date, flag) {
      if (!date) return '--';
      if (flag) {
        return moment(date).format('YYYY-MM-DD HH:mm:ss');
      } else {
        return moment(date).format('YYYY-MM-DD');
      }
    },
    getDetail(){
      this.columns=[];
      var myDate = new Date();
      this.currentTime = this.formatDate(myDate.getTime(),true);//申请时间，获取当前时间
      //信息返显
      const params = {
        access_token:this.accessToken,
        card_id:this.cardId
      }
      let res = this.$store.state.dataListTotal;
      // modifyInfo(params).then(res=>{
        this.dataList = res;
        var data = res[0];
        res.map(item => {
          this.columns = [...this.columns,item.contract_number]
        });
        if(data.wx_code == '200'){//未提交
          this.userName = data.customername;
          this.vin = data.vin;
          this.contractNum = this.columns[0];
          this.oldTel = data.mobile;
          this.oldTelFormat = this.formatMobileNoHide(this.oldTel);//原手机号码格式化
        }else if(data.wx_code == '506'){//已提交,信息返显且不可修改
          this.showDataDisable(data);
        }else{
          Toast.fail({
            message: data.msg,
            duration:'3000'
          });
        }
      // });
    },
    onContractConfirm(value) {
      var dataList = this.dataList;
      var wxCode = '';
      var index = '';
      this.contractNum = value;
      this.showContractPicker = false;
      this.dataList.map((item,i) => {
        if(item.contract_number == value){
          wxCode = item.wx_code;
          index = i
        }
      });
      this.vin = this.dataList[index].vin;
      this.userName = this.dataList[index].customername;
      this.oldTel = this.dataList[index].mobile;
      this.oldTelFormat = this.formatMobileNoHide(this.oldTel);//原手机号码格式化
      if(wxCode=='506'){
        this.showDataDisable(dataList[index]);
      }else if(wxCode=='200'){
        var myDate = new Date();
        this.newTel = '';
        this.currentTime = this.formatDate(myDate.getTime(),true);
        this.isReadonly = false;
      };
    },
    showDataDisable(data){//已提交过后不能再次提交且信息不能修改
      if(data.deal_status=='0'){
        Toast.success({
          message: "审核中",
          duration:'3000'
        });
      }else if(data.deal_status=='1'){
        Toast.success({
          message: "审核通过",
          duration:'3000'
        });
      }
        this.userName = data.customername;
        this.vin = data.vin;
        this.contractNum = data.contract_number;
        this.newTel = data.newmobile;
        this.oldTel = data.mobile;
        this.currentTime = data.apply_time;
         this.oldTelFormat = this.formatMobileNoHide(this.oldTel);//原手机号码格式化
        this.isReadonly = true;
        //data.approval_status,//0审核中/1通过/2退回/3退回再次申请
    },
    onSubmit(values) {
      console.log(values)
      var myDate = new Date();
      this.currentTime = this.formatDate(myDate.getTime(),true);
      const params = {
        access_token:this.accessToken,
        card_id:this.cardId,
        customername:values.userName,
        contract_number:values.contractNum,
        vin:values.vin,
        mobile:this.oldTel,
        newmobile:values.newTel,
        apply_time:this.currentTime
      }
      Dialog.confirm({
        message: '申请后无法撤销\n请确认是否提交',
      })
        .then(() => {
          modifyInfoSub(params).then(res=>{
            var data = res[0];
            const that = this;
            if(res.status=='200'){
              // this.onSubmitImg();
              Toast({
                message: '提交成功！',
                icon: require('@/assets/img/bindSuccess.png'),
                duration:'3000',
              });
              // this.getDetail();
              modifyInfo({access_token:this.accessToken,card_id:this.cardId}).then(res=>{
                this.columns = [];
                this.dataList = res.data;
                // var data = res[0];
                res.data.map(item => {
                  this.columns = [...this.columns,item.contract_number]
                });
                this.onContractConfirm(values.contractNum);
              })
            }else{
              Toast({
                message: res.msg,
                icon: require('@/assets/img/bindError.png'),
                duration:'3000'
              });
            }
          })
        })
        .catch(() => {
          // on cancel
        });
    },
  }
};
</script>
<style scoped>
.wrap{
    padding: 20px 0px;
    background-color: #fff;
  }
.van-button--default{
  border: 0px;
}
.tips{
  color:#808080;
  font-size:13px;
  margin-top: 20px;
}
.tips p{
  margin: 6px 15px;
}
</style>
<style>
.wrap .van-field__control{
  color: #666666 !important;
}
/* #app{
  background: #fff !important;
} */
</style>